<template>
    <span v-b-tooltip.hover :title="title">{{ text }}</span>
  </template>
  
  <script>
  export default {
    props: {
      text: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      }
    }
  };
  </script>
  